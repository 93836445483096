import { PartnerSettings } from '../types/PartnerSettings';

const defaultPartnerSettings: PartnerSettings = {
  id: 'liberis',
  displayName: 'Liberis',
  theme: {
    alpha: {
      primary: '100 55 226',
      secondary: '134 237 131',
      'core-1': '255 255 255',
      'core-2': '245 246 248',
      'core-3': '236 237 241',
      'core-4': '217 219 226',
      'core-5': '179 183 198',
      'core-6': '108 114 138',
      'core-7': '64 74 112',
      'core-8': '14 11 35',
      'success-1': '19 136 8',
      'success-2': '207 248 205',
      'warning-1': '255 104 37',
      'warning-2': '254 229 219',
      'error-1': '235 62 63',
      'error-2': '254 208 209',
      buttonBackgroundColor: 'var(--primary)',
      buttonTextColor: 'var(--core-1)',
      buttonBackgroundColorHover: 'var(--core-1)',
      buttonTextColorHover: 'var(--primary)',
      buttonDisabledBackgroundColor: 'var(--core-3)',
      buttonDisabledTextColor: 'var(--core-8)',
      textOnPrimary: 'var(--core-1)',
      contrastTextOnPrimary: 'var(--core-7)',
      checkboxColour: 'var(--primary)',
      darkenedPrimary: 'var(--primary)',
    },
    bravo: {
      primary: '100 55 226', // #6437E2
      secondary: '134 237 131',
      'core-1': '255 255 255',
      'core-2': '249 249 245',
      'core-3': '244 244 236', // #F4F4EC
      'core-4': '233 233 222',
      'core-5': '212 212 195', // #D4D4C3
      'core-6': '178 179 170',
      'core-7': '79 86 89', // #4F5659 (often seen as #505659 in Figma)
      'core-8': '35 44 47', // 252C2F
      'core-9': '0 0 0',
      'active-link': '65 100 234', // #4164EA
      'success-1': '19 136 8',
      'success-2': '207 248 205',
      'warning-1': '255 104 37',
      'warning-2': '254 229 219',
      'error-1': '235 62 63',
      'error-2': '254 208 209',
      buttonBackgroundColor: 'var(--primary)',
      buttonTextColor: 'var(--core-1)',
      buttonBackgroundColorHover: 'var(--core-1)',
      buttonTextColorHover: 'var(--primary)',
      buttonDisabledBackgroundColor: 'var(--core-3)',
      buttonDisabledTextColor: 'var(--core-8)',
      textOnPrimary: 'var(--core-1)',
      contrastTextOnPrimary: 'var(--core-7)',
      checkboxColour: 'var(--primary)',
      darkenedPrimary: 'var(--primary)',
    },
  },
  imagesPath: '/images/liberisCore/',
  images: {
    logo: 'default/logo.svg',
    'logo-dark': 'logo-dark.svg',
    aboutLiberisCrossbrand: 'default/aboutLiberisCrossbrand.png',
    heroImage: 'default/heroImage.png',
    ourCustomers1: 'default/letterbox4you.png',
    ourCustomers2: 'default/TwoMagpiesBakery.jpg',
    useCases1: 'default/LevelUp.png',
    useCases2: 'default/Cashflow.png',
    useCases3: 'default/Discovery.png',
    'explainer-video-url':
      'https://player.vimeo.com/video/864827830?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
    favicon:
      'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/default/favicon.ico',
  },
  language: 'en',
  singlePageJourney: false,
  allowReturnToJourney: true,
  showBD1Warning: false,
  enableIntercom: true,
  showDowntimeWarning: false,
  country: 'GB',
  countryName: 'United Kingdom',
  locale: 'en-GB',
  currency: 'GBP',
  currencySymbol: '£',
  contactPhoneNumber: '+44(0)1276-944512',
  contactEmail: 'info-uk@liberis.com',
  phoneNumberRegex: '^(0044|\\+?44|0)\\d{10}$',
  postcodeRegex:
    '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[ ]{0,1}[0-9][A-Za-z]{2})$',
  dateFormatMonthYear: 'MM/YYYY',
  dateFormatMonthYearRegex: '(0[1-9]|1[0-2])/[0-9]{4}',
  dateFormat: 'DD/MM/YYYY',
  'terms-and-conditions-url': 'https://www.liberis.com/terms-conditions/',
  'privacy-policy-url': 'https://www.liberis.com/privacy-policy/',
  'cookie-policy-url': 'https://www.liberis.com/cookie-policy/',
  'ace-dashboard-url': 'https://funding.liberis.com/login',
  businessTypeOptions: [
    { translationKey: 'business_type_sole_trader', value: 'Sole Trader' },
    {
      translationKey: 'business_type_limited_company',
      value: 'Limited Company',
    },
    { translationKey: 'business_type_partnership', value: 'Partnership' },
  ],
  businessSectorOptions: [
    { translationKey: 'industry_bar', value: 'Bar / Pub' },
    {
      translationKey: 'industry_car_sales',
      value: 'Car Sales / Garage Services',
    },
    { translationKey: 'industry_fashion', value: 'Fashion Retail' },
    { translationKey: 'industry_food_drink', value: 'Food / Drink Shop' },
    { translationKey: 'industry_general_store', value: 'General Store' },
    { translationKey: 'industry_hair_beauty', value: 'Hair & Beauty' },
    { translationKey: 'industry_healthcare', value: 'Healthcare' },
    { translationKey: 'industry_hotel', value: 'Hotel / B&B' },
    { translationKey: 'industry_other_retailer', value: 'Other Retailer' },
    { translationKey: 'industry_other_services', value: 'Other Services' },
    { translationKey: 'industry_restaurant', value: 'Restaurant' },
    {
      translationKey: 'industry_trade_contractor',
      value: 'Trade Contractor',
    },
  ],
  businessFundingUseCases: [
    {
      translationKey: 'intended_use_of_funds_purchase_stock',
      value: 'Purchase Stock',
    },
    {
      translationKey: 'intended_use_of_funds_new_equipment',
      value: 'Purchase New Equipment',
    },
    {
      translationKey: 'intended_use_of_funds_expand_business',
      value: 'Expand Business',
    },
    {
      translationKey: 'intended_use_of_funds_cash_flow',
      value: 'Cash Flow',
    },
    { translationKey: 'intended_use_of_funds_payroll', value: 'Payroll' },
    {
      translationKey: 'intended_use_of_funds_taxes_due',
      value: 'Taxes Due',
    },
    {
      translationKey: 'intended_use_of_funds_refurbishment',
      value: 'Refurbishment',
    },
    {
      translationKey: 'intended_use_of_funds_marketing',
      value: 'Marketing',
    },
    {
      translationKey: 'intended_use_of_funds_employing_staff',
      value: 'Employing Extra Staff',
    },
    {
      translationKey: 'intended_use_of_funds_safety_net',
      value: 'Safety Net',
    },
    {
      translationKey: 'intended_use_of_funds_consolidating',
      value: 'Consolidating',
    },
    { translationKey: 'intended_use_of_funds_other', value: 'Other' },
  ],
  ownershipStatusOptions: [
    { translationKey: 'ownership_status_renting', value: 'Renting' },
    { translationKey: 'ownership_status_home_owner', value: 'Home Owner' },
  ],
  languageLabels: [
    { value: 'en-GB', label: 'English' },
    { value: 'en-CA', label: 'English' },
    { value: 'fr-CA', label: 'Français' },
    { value: 'fi-FI', label: 'Suomi' },
  ],
  activeCustomerSettings: {
    AUTH0_BASE_URL: '',
    AUTH0_ORGANIZATION: '',
  },
};

export default defaultPartnerSettings;
